<template>
  <div id="ProBuy">
    <div class="ProBuy_in">
      <nav-tab-part1 pagetitle='我的订单'>
        <template v-slot:topRightText><span @click="goBack()">返回</span></template>
      </nav-tab-part1>
      <div class="main">
        <div class="order">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane
              v-for="(item, index) in tabarr"
              :key="index * Math.random()"
              :label="item.DictName"
              :name="item.DictID"
            >
              <template #label v-if="item.DictName == '待支付'">
                <span
                  >待支付
                  <span style="color: #409eff">{{
                    readynum == 0 ? "" : readynum
                  }}</span></span
                >
              </template>
              <div
                v-for="(item, index) in orderList"
                class="orderlist"
                :key="index"
              >
                <div class="top">
                  <div class="top_left">订单编号: {{ item.OrderNumber }}</div>
                  <div
                    class="top_right"
                    :style="{
                      color:
                        item.Status == 0 ||
                        item.Status == 1 ||
                        item.Status == 2 ||
                        item.Status == 3 ||
                        item.Status == 4
                          ? '#ff4949'
                          : '#389e0d',
                    }"
                  >
                    {{ getordername(item.Status) }}
                  </div>
                </div>
                <div class="order-productinfo">
                  <div class="productImg">
                    <img
                      class="proimg"
                      :src="imgUrlFront + item.ListImageUrl"
                    />
                  </div>
                  <div class="product-text">
                    <div class="pro-title">{{ item.ServiceItemName }}</div>
                    <div class="pro-spec">
                      {{ item.SpecName }}，{{ item.PeriodName }}
                    </div>
                  </div>
                  <div class="product-num">
                    <div class="product-price">
                      ￥{{
                        String((item.NowPrice / 100).toFixed(2)).split(".")[0]
                      }}.<span style="font-size: 14px">{{
                        String((item.NowPrice / 100).toFixed(2)).split(".")[1]
                      }}</span>
                    </div>
                    <div class="product-count">×1</div>
                  </div>
                </div>

                <div class="ordermiddle">
                  <div
                    class="middle-right"
                    v-if="item.CouponDescription != null"
                  >
                    <div class="pricetip" style="color: red">
                      优惠券
                      <span v-if="item.CouponTypeID == 2">
                        ￥-{{
                          item.NowPrice - (item.NowPrice * item.Discount) / 100
                        }}
                      </span>
                      <span v-else>￥-{{ item.ReduceAmount / 100 }}</span>
                    </div>
                  </div>
                  <div
                    class="middle-right"
                    style="justify-content: space-between"
                  >
                    <div class="counttip">共1件商品</div>
                    <div class="pricetip">
                      订单金额 ￥{{
                        String((item.PayPrice / 100).toFixed(2)).split(".")[0]
                      }}.<span style="font-size: 14px">{{
                        String((item.PayPrice / 100).toFixed(2)).split(".")[1]
                      }}</span>
                    </div>
                  </div>

                  <div
                    class="middle-right"
                    v-if="item.FrontMoney != item.PayPrice"
                    v-show="item.Status != 5"
                  >
                    <div
                      class="pricetip"
                      style="color: red"
                      v-if="item.FrontMoney == item.PayPrice"
                    >
                      预付金额 ￥{{
                        String((item.FrontMoney / 100).toFixed(2)).split(
                          "."
                        )[0]
                      }}.<span style="font-size: 14px">{{
                        String((item.FrontMoney / 100).toFixed(2)).split(".")[1]
                      }}</span>
                    </div>
                  </div>
                  <div class="middle-right" v-if="item.Status == 5">
                    <div class="pricetip" style="color: red">
                      尾款金额 ￥{{ (item.PayPrice - item.FrontMoney) / 100 }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="nodata" v-if="total == 0">暂无更多数据</div>
            </el-tab-pane>
          </el-tabs>
        </div>
        
        <div class="paging pcpaging" v-if="total > 0">
          <el-pagination
            background
            layout="prev,pager,next"
            @prev-click="prevclick"
            @next-click="nextclick"
            @current-change="currentclick"
            :current-page="currentPage"
            :total="total"
            :page-size="pageSize"
          >
          </el-pagination>
        </div>     

        <div class="paging phonepaging" v-if="total > 0">
          <el-pagination
            small
            background
            layout="prev,pager,next"
            @prev-click="prevclick"
            @next-click="nextclick"
            @current-change="currentclick"
            :current-page="currentPage"
            :total="total"
            :page-size="pageSize"
          >
          </el-pagination>
        </div>    

      </div>
      <footer-page></footer-page>
    </div>
  </div>
</template>

<script>
import navTabPart1 from "@/components/common/nav/navTab_part1.vue";
import footerPage from "@/components/common/footer/footerPage.vue";
import { getSession } from "@/utils/utils.js";
export default {
  name: "order",
  data() {
    return {
      tabarr: [],
      activeName: "all",
      readynum: 0,
      imgUrlFront: window.sessionStorage.getItem("apiImgUrl"), //图片前缀
      orderList: [], //订单页
      total: 0, //总数
      currentPage: 1,
      pageSize: 3,
    };
  },
  components: {
    navTabPart1,
    footerPage
  },
  created() {
    this.getordertab();
  },
  methods: {
    //根据status对应订单状态
    getordername(status) {
      switch (status) {
        case 0:
          return "未支付";
          break;
        case 1:
          return "进行中";
          break;
        case 2:
          return "审核中";
          break;
        case 3:
          return "进行中";
          break;
        case 4:
          return "正在办理";
          break;
        case 5:
          return "办理完成";
          break;
        case 6:
          return "已完成";
          break;
        case 7:
          return "已完成";
          break;
        case 8:
          return "已取消";
          break;
        case 9:
          return "未通过";
          break;
      }
    },
    //获取订单类型
    getordertab() {
      this.$axios("API/GetDict", {
        Version: "",
        DictTypeID: "DDXX_DDZT",
      }).then((res) => {
        this.tabarr = [{ DictName: "全部", DictID: "all" }];
        //全部订单
        let request = {
          Version: "",
          OrderUserGuid: this.$store.state.userinfo.UserGuid,
          OrderNumber: "",
          OrderStartTime: "",
          OrderEndTime: "",
          Status: "",
          PageIndex: this.currentPage,
          PageSize: this.pageSize,
          JZToken: this.$store.state.userinfo.TokenCode,
          UserGuid: this.$store.state.userinfo.UserGuid,
        };
        this.$axios("/API/Get_Order", request).then((res) => {
          this.total = res.count;
          this.orderList = res.data;
        });
        res.data.forEach((item) => {
          this.tabarr.push(item);
          if (item.DictName == "待支付") {
            let request = {
              Version: "",
              OrderUserGuid: this.$store.state.userinfo.UserGuid,
              OrderNumber: "",
              OrderStartTime: "",
              OrderEndTime: "",
              Status: item.DictID,
              PageIndex: this.currentPage,
              PageSize: this.pageSize,
              JZToken: this.$store.state.userinfo.TokenCode,
              UserGuid: this.$store.state.userinfo.UserGuid,
            };
            this.$axios("/API/Get_Order", request).then((res) => {
              this.readynum = res.count;
            });
          }
        });
      });
    },
    //获取订单
    getorder(dictid,pageindex) {
      let request = {
        Version: "",
        OrderUserGuid: this.$store.state.userinfo.UserGuid,
        OrderNumber: "",
        OrderStartTime: "",
        OrderEndTime: "",
        Status: dictid,
        PageIndex: pageindex,
        PageSize: this.pageSize,
        JZToken: this.$store.state.userinfo.TokenCode,
        UserGuid: this.$store.state.userinfo.UserGuid,
      };
      this.$axios("/API/Get_Order", request).then((res) => {
        this.total = res.count;
        this.orderList = res.data;
      });
    },

    //上一页
    prevclick() {
      this.currentPage -= 1;
      this.getorder(this.activeName == "all" ? "" : this.activeName,this.currentPage);
    },
    //下一页
    nextclick() {
      this.currentPage += 1;
      this.getorder(this.activeName == "all" ? "" : this.activeName,this.currentPage);
    },
    //页码改变，当前页
    currentclick(e) {
      this.currentPage = e;
      this.getorder(this.activeName == "all" ? "" : this.activeName,this.currentPage);
    },
    //获取不同类型订单数量
    handleClick(e) {
      this.activeName == e.name;
      this.getorder(this.activeName == "all" ? "" : this.activeName,1);
    },
    toindex() {
      this.$router.push("/home");
    },
    //返回上一页
    goBack(){
      this.$router.go(-1)
    }
  },
};
</script>

<style lang="scss">
    @media(min-width: 1201px){
      @import "orderlist.scss";
    }
    @media(max-width: 1200px){
      @import 'OrderList_phone.scss';
    }
</style>
